@import '@angular/material/theming';
$theme-color: #930027;

$mat-iopen-red: (
  50: #f2e0e5,
  100: #dfb3be,
  200: #c98093,
  300: #b34d68,
  400: #a32647,
  500: #930027,
  600: #8b0023,
  700: #80001d,
  800: #760017,
  900: #64000e,
  A100: #ff949a,
  A200: #ff616a,
  A400: #ff2e3a,
  A700: #ff1422,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
) !default;
@import 'theme-base';
