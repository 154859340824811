@use '@angular/material' as mat;

// @import './styles-vars.scss';
// BOOTSTRAP Variables
// must always be declared BEFORE the bootstrap imports

$theme-color: #930027 !default;
$secondary: #930027 !default;
$blue: #007bff !default;
$background: white !default;

$enable-responsive-font-sizes: true !default;
$enable-gradients: true !default;
$enable-shadows: true !default;
$enable-validation-icons: false !default;

$carousel-control-color: $theme-color !default;
$theme-colors: (
  primary: $theme-color,
  secondary: $secondary,
  main: $theme-color,
  background: $background,
  "blue": $blue
) !default;

$theme-foreground: (

) !default;

// $link-color: $blue !default;
// $link-hover-color: darken($link-color, 15%) !default;

$pagination-border-color: $theme-color !default;
$pagination-hover-border-color: $theme-color !default;
$pagination-color: $theme-color !default;
$pagination-hover-color: white !default;
$pagination-hover-bg: $theme-color !default;
$pagination-disabled-border-color: $theme-color !default;
$pagination-disabled-color: $theme-color !default;


$yiq-contrasted-threshold: 195 !default;
$form-group-margin-bottom: 0.51rem !default;
$card-spacer-x: 0.75rem !default;
$card-spacer-y: 0.75rem !default;
$table-cell-padding: 0.51rem !default;
// $card-spacer-y: 1.02rem !default;

$input-placeholder-color: $theme-color !default; // allow Tenant override


$sizes: (
  1: 1%,
  2: 2%,
  3: 3%,
  4: 4%,
  5: 5%,
  6: 6%,
  8: 8%,
  10: 10%,
  13: 13%,
  14: 14%,
  15: 15%,
  18: 18%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  50: 50%,
  60: 60%,
  75: 75%,
  95: 95%,
  99: 99%,
  100: 100%
);

// Import ALL of Bootstrap (heavy)
//@import '~bootstrap/scss/bootstrap';

// Import Parts of Bootstrap (lighter)
// CORE BOOTSTRAP
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// COMPONENTS
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
//@import '~bootstrap/scss/type';
//@import '~bootstrap/scss/images';
//@import '~bootstrap/scss/code';
//@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
//@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
//@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
//@import '~bootstrap/scss/progress';
//@import '~bootstrap/scss/media';
//@import '~bootstrap/scss/list-group';
//@import '~bootstrap/scss/close';
//@import '~bootstrap/scss/toasts';
//@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
//@import '~bootstrap/scss/popover';
//@import '~bootstrap/scss/carousel';
//@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
//@import '~bootstrap/scss/print';

//@import '~bootstrap/scss/mixins/text-emphasis';

@import '@angular/material/theming';
/*
$mat-iopen-red: (
  50: #f2e0e5,
  100: #dfb3be,
  200: #c98093,
  300: #b34d68,
  400: #a32647,
  500: #930027,
  600: #8b0023,
  700: #80001d,
  800: #760017,
  900: #64000e,
  A100: #ff949a,
  A200: #ff616a,
  A400: #ff2e3a,
  A700: #ff1422,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
) !default;
*/

// TODO: Separate this out for smarter and more flexible material theme generation
$mat-iopen-accent: $mat-iopen-red !default;

$candy-app-primary: mat.define-palette($mat-iopen-red);
$candy-app-accent:  mat.define-palette($mat-iopen-accent);// , A200, A100, A400);
$candy-app-warn:    mat.define-palette(mat.$red-palette);
$candy-app-theme:   mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
@include mat.all-component-themes(map-merge($candy-app-theme, $theme-foreground));


/**
  BOOTSTRAP OVERRIDES
  Custom Bootstrap Checkbox
**/
.custom-control-input {
  position: relative;
}
